import React from "react";
import trustmark from '../trustmark.png'

export default class Schedule extends React.Component {
    render() {
        return(
            <div className="card schedule-form contact-form">

<div className="calendly-inline-widget" data-url="https://calendly.com/216digital/ada-quick-audit?background_color=e8f5f7"></div>

            </div>
        )
    }
}